.App {
  color: #fff;
  height: 100vh;
  text-align: center;
  background-color: #47141e
}

.App-logo {
  margin-top: 50px;
  height: 50vmin;
  pointer-events: none;
}

